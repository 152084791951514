import { stepImage, adDiv } from '../../pages/receta/{mysqlAllRecipes.pageUrl}.module.css'
import React from "react"
import { buildIngredients } from "./ContentHelper"
import { getImage } from "gatsby-plugin-image"
import { GatsbyImage } from 'gatsby-plugin-image'
import Video from "../../components/video"

const ContentFullHtml = ({ recipeData }) => {

    const ingredients = buildIngredients(recipeData, true);

    const fullHtmlReplaced = recipeData.fullHtml.replace(/<h2/g, "<h2 class='fullHtmlH2'")
        .replace(/<p/g, "<p class='fullHtmlP'")
        .replace(/<ul/g, "<ul class='fullHtmlIngredientsUl'")
        .replace(/<li/g, "<li class='fullHtmlIngredientsLi'")
        .replace(/{INGREDIENTS}/g, ingredients)
        .replace(/YouTube/g, '<a href="https://www.youtube.com/FedeCocina" target="_blank" rel="noreferrer" aria-label="YouTube">YouTube</a>')
        .replace(/Youtube/g, '<a href="https://www.youtube.com/FedeCocina" target="_blank" rel="noreferrer" aria-label="YouTube">YouTube</a>')
        .replace(/Instagram/g, '<a href="https://www.instagram.com/fede.cocina.ya" target="_blank" rel="noreferrer" aria-label="Instagram">Instagram</a>')
        .replace(/Facebook/g, '<a href="https://www.facebook.com/fede.cocina.ya" target="_blank" rel="noreferrer" aria-label="Facebook">Facebook</a>');

    const imageYoutubeThumbnail = recipeData.youtubeThumbnail ? getImage(recipeData.youtubeThumbnail.childImageSharp) : null;

    var indexSplit1 = fullHtmlReplaced.indexOf('{VIDEO}');
    var indexSplit3 = fullHtmlReplaced.indexOf('{PROCEDURE}');
    var indexSplit2 = fullHtmlReplaced.substring(0, indexSplit3).lastIndexOf('<h2 class=\'fullHtmlH2\'');
    var fullHtmlReplaced1 = fullHtmlReplaced.substring(0, indexSplit1);
    var fullHtmlReplaced2 = fullHtmlReplaced.substring(indexSplit1 + 7, indexSplit2);
    var fullHtmlReplaced3 = fullHtmlReplaced.substring(indexSplit2, indexSplit3);
    var fullHtmlReplaced4 = fullHtmlReplaced.substring(indexSplit3 + 11);

    if (indexSplit1 < 0) {
        fullHtmlReplaced1 = '';
        fullHtmlReplaced2 = fullHtmlReplaced.substring(0, indexSplit2);
    }

    return (
        <div>
            <div dangerouslySetInnerHTML={{__html: fullHtmlReplaced1}}></div>
            { recipeData.youtubeUrl ?
                <Video
                    videoSrcURL={recipeData.youtubeUrl}
                    videoTitle={recipeData.name}
                    imageYoutubeThumbnail={imageYoutubeThumbnail}
                    shortName={recipeData.shortName}
                />
                :
                <></>
            }
            <div dangerouslySetInnerHTML={{__html: fullHtmlReplaced2}}></div>
            <div className={adDiv}>
                <ins class="adsbygoogle"
                    style="display:block; text-align:center;"
                    data-ad-layout="in-article"
                    data-ad-format="fluid"
                    data-ad-client="ca-pub-8506919019942528"
                    data-ad-slot="1071179520"></ins>
            </div>
            <div dangerouslySetInnerHTML={{__html: fullHtmlReplaced3}}></div>
            <ul class="fullHtmlProcedureStepsUl">
                { recipeData.recipeSteps && recipeData.recipeSteps.size > 0 ?
                    recipeData.recipeSteps.map((item, index) => {              
                        const image = item.image ? getImage(item.image.childImageSharp) : null;
                        return (
                            <li id={'instruccion-' + index} class="fullHtmlProcedureStepsLi">
                                <p>{item.description.replace(/\n/g, ``)}</p>
                                <GatsbyImage v-if={image} className={stepImage} image={image} alt={item.description}/>
                            </li>
                        )
                    })
                    :
                    recipeData.procedureSteps.split('\n').map((description, index) => {              
                        return (
                            <li id={'instruccion-' + index} class="fullHtmlProcedureStepsLi">
                                <p>{description.replace(/\n/g, ``)}</p>
                            </li>
                        )
                    })
                }
            </ul>
            <div className={adDiv}>
                <ins class="adsbygoogle"
                    style="display:block; text-align:center;"
                    data-ad-layout="in-article"
                    data-ad-format="fluid"
                    data-ad-client="ca-pub-8506919019942528"
                    data-ad-slot="1761730077"></ins>
            </div>
            <div dangerouslySetInnerHTML={{__html: fullHtmlReplaced4}}></div>
        </div>
  )
}

export default ContentFullHtml