import { introText, ingredientsContainer, ingredientsSpan, procedureStepsContainer, procedureStepsSpan, 
    ingredientsStepsContainer, ingredientsListDiv, stepImage, videoSpan, adDiv } from '../../pages/receta/{mysqlAllRecipes.pageUrl}.module.css'
import React from "react"
import { buildIngredients } from "./ContentHelper"
import { getImage } from "gatsby-plugin-image"
import { GatsbyImage } from 'gatsby-plugin-image'
import Video from "../../components/video"

const ContentNoHtml = ({ recipeData }) => {

    const ingredients = buildIngredients(recipeData, false);
    const imageYoutubeThumbnail = getImage(recipeData.youtubeThumbnail.childImageSharp);

    return (
        <div>
            <p className={introText}>
                {recipeData.introText}
            </p>
            <div className={ingredientsStepsContainer}>
                <div className={ingredientsContainer}>
                <h2 className={videoSpan}>Video</h2>
                <Video
                    videoSrcURL={recipeData.youtubeUrl}
                    videoTitle={recipeData.name}
                    imageYoutubeThumbnail={imageYoutubeThumbnail}
                    shortName={recipeData.shortName}
                />
                <h2 className={ingredientsSpan}>Ingredientes</h2>
                <div className={ingredientsListDiv} dangerouslySetInnerHTML={{__html: ingredients}}></div>
                </div>
                <div className={procedureStepsContainer}>
                    <div className={adDiv}>
                        <ins class="adsbygoogle"
                            style="display:block; text-align:center;"
                            data-ad-layout="in-article"
                            data-ad-format="fluid"
                            data-ad-client="ca-pub-8506919019942528"
                            data-ad-slot="1071179520"></ins>
                    </div>
                    <h2 className={procedureStepsSpan}>Instrucciones</h2>
                    <ul class="procedureStepsUl">
                        { recipeData.recipeSteps.map((item, index) => {              
                            const image = item.image ? getImage(item.image.childImageSharp) : null;
                            return (
                                <li id={'instruccion-' + index} class="procedureStepsLi">
                                    <p>{item.description.replace(/\n/g, ``)}</p>
                                    <GatsbyImage v-if={image} className={stepImage} image={image} alt={item.description}/>
                                </li>
                            )
                        })
                        }
                    </ul>
                    <div className={adDiv}>
                        <ins class="adsbygoogle"
                            style="display:block; text-align:center;"
                            data-ad-layout="in-article"
                            data-ad-format="fluid"
                            data-ad-client="ca-pub-8506919019942528"
                            data-ad-slot="1761730077"></ins>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default ContentNoHtml