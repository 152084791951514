export default function getReadableDuration(time) {
    const minutesRegex = /(\d+)M/g;
    const hoursRegex = /(\d+)H/g;
    var minutesFound = time.match(minutesRegex);
    var hoursFound = time.match(hoursRegex);
    if (minutesFound) {
      minutesFound = minutesFound[0];
      minutesFound = minutesFound.substring(0, minutesFound.length - 1);
    }
    if (hoursFound) {
      hoursFound = hoursFound[0];
      hoursFound = hoursFound.substring(0, hoursFound.length - 1);
    }
    if (minutesFound && hoursFound) {
      return hoursFound + " Horas y " + minutesFound + " Minutos";
    } else if (minutesFound) {
      return minutesFound + " Minutos";
    } else if (hoursFound) {
      return hoursFound + " Horas";
    }
  }