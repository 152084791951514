import { innerDiv, title, topTitleBreadcrumbs, leftColumn, rightColumn, columnsContainer, relatedRecipesMainDiv, 
  relatedRecipesTitleSpan, relatedRecipesInnerDiv, relatedRecipesBackgroundDiv,
  introTextTop, publishAtP, goToCategoryMainDiv, breadcrumbLink, breadcrumbArrow, 
  goToCategoryButton, cookingTimesDiv, cookingTimesP, servingSizeP, adDiv, imageSquaredImage } from './{mysqlAllRecipes.pageUrl}.module.css'
import React, { useState, useEffect } from "react"
import NavBar from "../../components/NavBar"
import { graphql } from 'gatsby'
import Footer from "../../components/Footer"
import RecipeRightBar from "../../components/RecipeRightBar/RecipeRightBar"
import { getImage } from "gatsby-plugin-image"
import DishCard from "../../components/HomeDishes/DishCard"
import { Helmet } from "react-helmet"
import { navigate } from 'gatsby';
import { Link } from "gatsby"
import getReadableDuration from "../../utils/timeUtils";
import Comments from "../../components/Comments"
import getStructuredData from "../../components/Recipe/StructureData"
import NutritionData from "../../components/Recipe/NutritionData"
import ContentNoHtml from "../../components/Recipe/ContentNoHtml"
import ContentFullHtml from "../../components/Recipe/ContentFullHtml"
import CarouselHome from "../../components/CarouselHome"
import { GatsbyImage } from 'gatsby-plugin-image'

const RecipePage = ({ data }) => {
  const recipeData = data.allMysqlAllRecipes.nodes[0];

  const goToCategory = () => {
    navigate(`/recetas/` + recipeData.categoryPageUrl + '/');
  }

  const showFullHtml = recipeData.fullHtml ? true : false;
  const imageYoutubeThumbnail = recipeData.youtubeThumbnail ? getImage(recipeData.youtubeThumbnail.childImageSharp) : null;
  const imageSquared = recipeData.squaredImg ? getImage(recipeData.squaredImg.childImageSharp) : null;

  const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
  var publishedAt = "";
  if (recipeData.publishedAt) {
    publishedAt = new Date(Date.parse(recipeData.publishedAt)).toLocaleDateString("es-ES", dateOptions);
  }

  useEffect(() => {
    try {
      if (!adsbygoogle) {
        var adsbygoogle = null;
      }
      adsbygoogle = window.adsbygoogle || [];
      for (var i = 0; i < 5; i++) {
        adsbygoogle.push({});
      }
    } catch (err) {}
  }, [])

  return (
    <div>
      <Helmet>
          <script async src="//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
          <script>
            {` (adsbygoogle = window.adsbygoogle || []).push({
                google_ad_client: "ca-pub-8506919019942528",
                enable_page_level_ads: true,
                overlays: {bottom: true}
              });`}
          </script>
          <meta charSet="utf-8" />
          <title>{recipeData.shortName} - Fede Cocina</title>
          <meta name="description" content={recipeData.introText}></meta>
          <html lang="es"/>
          <meta property="og:title" content={recipeData.shortName}></meta>
          <meta property="og:description" content={recipeData.introText}></meta>
          <meta property="og:image" content={"https://fedecocina.net/imagenes/16x9/" + recipeData.thumbName + ".jpg"}></meta>
          <meta property="og:image:width" content="1280"></meta>
          <meta property="og:image:height" content="720"></meta>
          <meta property="og:type" content="article"></meta>
          <meta property="og:locale" content="es_ES"></meta>
          <meta property="og:site_name" content="fedecocina.net"></meta>
          <script type="application/ld+json">
            {getStructuredData(recipeData)}
          </script>
        </Helmet>
      <NavBar></NavBar>
      { recipeData.youtubeUrl ?
        <></>
        :
        <CarouselHome whiteBackground={true}></CarouselHome>
      }
      <div className={innerDiv}>
        <p className={topTitleBreadcrumbs}>
          <Link className={breadcrumbLink} to={`/`}>Inicio</Link>
          <span className={breadcrumbArrow}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true"><path d="M8.578 16.359l4.594-4.594-4.594-4.594 1.406-1.406 6 6-6 6z"></path></svg> </span>
          <Link className={breadcrumbLink} to={`/todas-las-recetas/`}>Recetas</Link>
          <span className={breadcrumbArrow}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true"><path d="M8.578 16.359l4.594-4.594-4.594-4.594 1.406-1.406 6 6-6 6z"></path></svg> </span>
          <Link className={breadcrumbLink} to={`/recetas/` + recipeData.categoryPageUrl + "/"}>{recipeData.category}</Link>
        </p>
        <h1 className={title}>{recipeData.shortName}</h1>
        <div className={columnsContainer}>
          <div className={leftColumn}>
            { showFullHtml ? 
              <p className={introTextTop}>{recipeData.introText}</p>
              :
              <></>
            }
            <GatsbyImage image={imageYoutubeThumbnail} alt={recipeData.shortName}/>
            <div className={cookingTimesDiv}>
              { recipeData.prepTime ? 
                <p className={cookingTimesP}><b>Tiempo de Preparación</b>: {getReadableDuration(recipeData.prepTime)}</p>
                :
                <></>
              }
              { recipeData.cookTime ? 
                <p className={cookingTimesP}><b>Tiempo de Cocción</b>: {getReadableDuration(recipeData.cookTime)}</p>
                :
                <></>
              }
              { recipeData.totalTime ? 
                <p className={cookingTimesP}><b>Tiempo Total</b>: {getReadableDuration(recipeData.totalTime)}</p>
                :
                <></>
              }
            </div>
            <p className={servingSizeP}><b>Porciones</b>: {recipeData.recipeYield + ' ' + recipeData.recipeYieldTypePlural}</p>
            <div className={adDiv}>
              <ins class="adsbygoogle"
                  style="display:block; text-align:center;"
                  data-ad-layout="in-article"
                  data-ad-format="fluid"
                  data-ad-client="ca-pub-8506919019942528"
                  data-ad-slot="1643408057"></ins>
            </div>
              { showFullHtml ?
                <ContentFullHtml recipeData={recipeData}></ContentFullHtml>
              :
                <ContentNoHtml recipeData={recipeData}></ContentNoHtml>
              }
              <GatsbyImage className={imageSquaredImage} image={imageSquared} alt={recipeData.shortName}/>
              <div className={adDiv}>
                  <ins class="adsbygoogle"
                      style="display:block; text-align:center;"
                      data-ad-layout="in-article"
                      data-ad-format="fluid"
                      data-ad-client="ca-pub-8506919019942528"
                      data-ad-slot="8402890547"></ins>
              </div>
              <NutritionData recipeData={recipeData}></NutritionData>
              <p className={publishAtP}>Publicado por Federico Quevedo, el {publishedAt}.</p>
              <div className={goToCategoryMainDiv} onClick={() => goToCategory()}>¡Si querés preparar más recetas de {recipeData.category} hace clic acá para ver un recetario impresionante!</div>
              <button className={goToCategoryButton} onClick={() => goToCategory()}>Ir a Recetario</button>
          </div>
          <div className={rightColumn}>
            <RecipeRightBar></RecipeRightBar>
          </div>
        </div>
      </div>
      <div className={relatedRecipesBackgroundDiv}>
        <div className={relatedRecipesMainDiv}>
          <h2 className={relatedRecipesTitleSpan}>Más recetas para que disfrutes</h2>
          <div className={relatedRecipesInnerDiv}>
            { recipeData.relatedRecipes.map((item) => {
              const image = getImage(item.image.childImageSharp);
              return (
                <DishCard key={item.id} pageUrl={item.pageUrl} image={image} name={item.shortName} shortText={item.introText} category={item.category} rate={item.rate} slimMargin={false}></DishCard>
              )
            })
            }
          </div>
          <div className={adDiv}>
              <ins class="adsbygoogle"
                  style="display:block; text-align:center;"
                  data-ad-layout="in-article"
                  data-ad-format="fluid"
                  data-ad-client="ca-pub-8506919019942528"
                  data-ad-slot="3505771173"></ins>
          </div>
        </div>
      </div>
      <div className={innerDiv}>
        <div className={columnsContainer}>
          <div className={leftColumn}>
           <Comments recipeData={recipeData}></Comments>
          </div>
          <div className={rightColumn}>

          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  )
}

export const query = graphql`
  query($pageUrl: String) {
    allMysqlAllRecipes(filter: {pageUrl: {eq: $pageUrl}}) {
      nodes {
        id
        name
        shortName
        pageUrl
        category
        categoryPageUrl
        youtubeUrl
        ingredients
        procedureSteps
        introText
        fullHtml
        publishedAt
        rate
        thumbName
        SDRecipeCategory
        SDVideoDuration
        SDRecipeCuisine
        tags
        prepTime
        cookTime
        totalTime
        recipeYield
        recipeYieldGrams
        recipeYieldType
        recipeYieldTypePlural
        nutritionJson
        ratingCount
        youtubeThumbnail {
          childImageSharp {
            gatsbyImageData(width: 1920, placeholder: BLURRED, breakpoints: [1200, 900, 650, 400])
          }
        }
        squaredImg {
          childImageSharp {
            gatsbyImageData(width: 1200, placeholder: BLURRED, breakpoints: [1200, 900, 650, 400])
          }
        }
        relatedRecipes {
          id
          image {
            childImageSharp {
              gatsbyImageData(width: 450, placeholder: BLURRED)
            }
          }
          shortName
          category
          introText
          pageUrl
          rate
        }
        recipeSteps {
          fileName
          description
          image {
            childImageSharp {
              gatsbyImageData(width: 1920, placeholder: BLURRED, breakpoints: [1200, 800, 600, 400])
            }
          }
        }
      }
    }
  }
`

export default RecipePage
