import { nutritionH, nutritionP, nutritionDisclaimerP } from '../../pages/receta/{mysqlAllRecipes.pageUrl}.module.css'
import React from "react"
import { Link } from "gatsby"

const NutritionData = ({ recipeData }) => {
    
    const nutrition = JSON.parse(recipeData.nutritionJson);

    return (
        <div>
             <h2 className={nutritionH}>Nutrición</h2>
              <p className={nutritionP}>Porción: 1 {recipeData.recipeYieldType ? recipeData.recipeYieldType : ''} ( {recipeData.recipeYieldGrams ? recipeData.recipeYieldGrams : 0}g ) | Calorias: {nutrition ? nutrition.calories : 0}kcal | 
                Carbohidratos: {nutrition ? nutrition.carbohydrateContent : 0}g | Proteínas: {nutrition ? nutrition.proteinContent : 0}g | 
                Grasas: {nutrition ? nutrition.fatContent : 0}g | Grasas saturadas: {nutrition ? nutrition.saturatedFatContent : 0}g | 
                Colesterol: {nutrition ? nutrition.cholesterolContent : 0}mg | Sodio: {nutrition ? nutrition.sodiumContent : 0}mg | 
                Azúcar: {nutrition ? nutrition.sugarContent : 0}g | Fibra: {nutrition ? nutrition.fiberContent : 0}g | Grasas Insaturadas: {nutrition ? nutrition.unsaturatedFatContent : 0}g |
                Grasas Trans: {nutrition ? nutrition.transFatContent : 0}g</p>
              <Link to={'/paginas/disclaimers/'} className={nutritionDisclaimerP} target="_blank">*Disclaimer Nutricional</Link>
        </div>
  )

}

export default NutritionData