export function buildIngredients(recipeData, stylesAsFullHtml) {
    const styleUl = stylesAsFullHtml ? "fullHtmlIngredientsUl" : "ingredientsUl";
    const styleLi = stylesAsFullHtml ? "fullHtmlIngredientsLi" : "ingredientsLi";
    const styleTitleLi = stylesAsFullHtml ? "fullHtmlIngredientsTitleLi" : "ingredientsTitleLi";
  
    var retval = "<ul class=\"" + styleUl + "\">";
    var ingredientsLines = recipeData.ingredients.split(`\n`);
    
    for (var i = 0; i < ingredientsLines.length; i++) {
      var line = ingredientsLines[i];
      var liClass = styleLi;
  
      if (line.charAt(0) !== '-') { 
        liClass = styleTitleLi;
      } else {
        line = line.substring(1);  
      }
  
      retval += `<li class="${liClass}"><p>${line.replace(/\n/g, ``)}</p></li>`;
    }
    retval += "</ul>";
  
    return retval;
};