// extracted by mini-css-extract-plugin
export var innerDiv = "{mysqlAllRecipes-pageUrl}-module--innerDiv--1mrPb";
export var title = "{mysqlAllRecipes-pageUrl}-module--title--18rCZ";
export var topTitleBreadcrumbs = "{mysqlAllRecipes-pageUrl}-module--topTitleBreadcrumbs--1LTGo";
export var breadcrumbLink = "{mysqlAllRecipes-pageUrl}-module--breadcrumbLink--ZgoNa";
export var breadcrumbArrow = "{mysqlAllRecipes-pageUrl}-module--breadcrumbArrow--2Jb0K";
export var columnsContainer = "{mysqlAllRecipes-pageUrl}-module--columnsContainer--n8-hB";
export var leftColumn = "{mysqlAllRecipes-pageUrl}-module--leftColumn--3-_RO";
export var rightColumn = "{mysqlAllRecipes-pageUrl}-module--rightColumn--3NLz7";
export var introText = "{mysqlAllRecipes-pageUrl}-module--introText--3WmEG";
export var introTextTop = "{mysqlAllRecipes-pageUrl}-module--introTextTop--3ygI3";
export var ingredientsContainer = "{mysqlAllRecipes-pageUrl}-module--ingredientsContainer--3i7dA";
export var ingredientsSpan = "{mysqlAllRecipes-pageUrl}-module--ingredientsSpan--1-7Nb";
export var videoSpan = "{mysqlAllRecipes-pageUrl}-module--videoSpan--2vqRn";
export var procedureStepsContainer = "{mysqlAllRecipes-pageUrl}-module--procedureStepsContainer--2HLhT";
export var relatedRecipesBackgroundDiv = "{mysqlAllRecipes-pageUrl}-module--relatedRecipesBackgroundDiv--dnG1P";
export var relatedRecipesMainDiv = "{mysqlAllRecipes-pageUrl}-module--relatedRecipesMainDiv--1CRPm";
export var relatedRecipesInnerDiv = "{mysqlAllRecipes-pageUrl}-module--relatedRecipesInnerDiv--1JQpu";
export var relatedRecipesTitleSpan = "{mysqlAllRecipes-pageUrl}-module--relatedRecipesTitleSpan--rILPk";
export var publishAtP = "{mysqlAllRecipes-pageUrl}-module--publishAtP--N4fii";
export var goToCategoryMainDiv = "{mysqlAllRecipes-pageUrl}-module--goToCategoryMainDiv--2EESC";
export var goToCategoryButton = "{mysqlAllRecipes-pageUrl}-module--goToCategoryButton--27J2S";
export var cookingTimesDiv = "{mysqlAllRecipes-pageUrl}-module--cookingTimesDiv--1op7F";
export var cookingTimesP = "{mysqlAllRecipes-pageUrl}-module--cookingTimesP--APjxW";
export var servingSizeP = "{mysqlAllRecipes-pageUrl}-module--servingSizeP--3Pwrz";
export var nutritionH = "{mysqlAllRecipes-pageUrl}-module--nutritionH--1EU37";
export var nutritionP = "{mysqlAllRecipes-pageUrl}-module--nutritionP--3OS_3";
export var nutritionDisclaimerP = "{mysqlAllRecipes-pageUrl}-module--nutritionDisclaimerP--2ICU6";
export var ingredientsListDiv = "{mysqlAllRecipes-pageUrl}-module--ingredientsListDiv--1L83W";
export var procedureStepsSpan = "{mysqlAllRecipes-pageUrl}-module--procedureStepsSpan--1OHxN";
export var procedureStepsListDiv = "{mysqlAllRecipes-pageUrl}-module--procedureStepsListDiv--3PH6s";
export var stepImage = "{mysqlAllRecipes-pageUrl}-module--stepImage--2KZ-I";
export var adDiv = "{mysqlAllRecipes-pageUrl}-module--adDiv--2P-c6";
export var imageSquaredImage = "{mysqlAllRecipes-pageUrl}-module--imageSquaredImage--1sj-P";
export var ingredientsStepsContainer = "{mysqlAllRecipes-pageUrl}-module--ingredientsStepsContainer--1njT1";