export default function getStructuredData(recipeData) {
    function buildRecipeIngredient(recipeData) {
        var ingredientsLines = recipeData.ingredients.split(`\n`);
        var retval = "";
        
        for (var i = 0; i < ingredientsLines.length; i++) {
            var line = ingredientsLines[i];
            if (!line) {
            continue;
            }
        
            retval +=`"${line.replace('\-','')}",`;
        }
        
        retval = retval.substring(0, retval.length - 1);
        return retval;
    }
        
    function buildRecipeInstructions(recipeData) {
        var retval = "";
        
        for (var i = 0; i < recipeData.recipeSteps.length; i++) {
          var step = recipeData.recipeSteps[i];
          retval +=
          `{
              "@type": "HowToStep",
              "text": "` + step.description + `",
              "name": "` + step.description + `",
              "url": "https://fedecocina.net/receta/` + recipeData.pageUrl + `/#instruccion-${i}"`;

          if (step.fileName) {
            var stepImg = step.image.childImageSharp.gatsbyImageData.images.fallback.src;
            retval += `, 
              "image": "https://fedecocina.net` + stepImg + `"`;
          }

          retval +=`
          },`;
        }
        
        retval = retval.substring(0, retval.length - 1);
        
        return retval;
    }

    var aggregateRating = "";
    if (recipeData.rate >= 0) {
      aggregateRating = `"aggregateRating": {
                            "@type": "AggregateRating",
                            "ratingValue": "` + recipeData.rate + `",
                            "ratingCount": "` + recipeData.ratingCount + `"
                          },`;
    }                

    const primaryImage = recipeData.youtubeThumbnail ? recipeData.youtubeThumbnail.childImageSharp.gatsbyImageData.images.fallback.src : null;
    const squaredImage = recipeData.squaredImg ? recipeData.squaredImg.childImageSharp.gatsbyImageData.images.fallback.src : null;

    const isoDate = recipeData.publishedAt ? new Date(Date.parse(recipeData.publishedAt)).toISOString() : '';
    const recipeIngredient = buildRecipeIngredient(recipeData);
    const recipeInstructions = buildRecipeInstructions(recipeData);
    const prepTime = recipeData.prepTime ? `"prepTime": "${recipeData.prepTime}",` : "";
    const cookTime = recipeData.cookTime ? `"cookTime": "${recipeData.cookTime}",` : "";
    const totalTime = recipeData.totalTime ? `"totalTime": "${recipeData.totalTime}",` : "";
    var videoStructure = null;
    if (recipeData.youtubeUrl) {
      videoStructure = `"video": {
          "name": "` + recipeData.name + `",
          "description": "` + recipeData.introText + `",
          "uploadDate": "` + isoDate + `",
          "duration": "` + recipeData.SDVideoDuration + `",
          "thumbnailUrl": "https://i.ytimg.com/vi/` + recipeData.youtubeUrl + `/hqdefault.jpg",
          "contentUrl": "https://youtu.be/` + recipeData.youtubeUrl + `",
          "embedUrl": "https://www.youtube.com/embed/` + recipeData.youtubeUrl + `?feature=oembed",
          "@type": "VideoObject"
        },`;
    } else {
      videoStructure = '';
    }

    const nutrition = JSON.parse(recipeData.nutritionJson);
    
    return `{
        "@context": "https://schema.org/",
        "@graph": [
          {
            "@type": "Organization",
            "@id": "https://fedecocina.net/#organization",
            "name": "Fede Cocina",
            "url": "https://fedecocina.net/",
            "sameAs": [
              "https://www.facebook.com/fede.cocina.ya",
              "https://www.instagram.com/fede.cocina.ya",
              "https://www.youtube.com/FedeCocina"
            ],
            "logo": {
              "@type": "ImageObject",
              "@id": "https://fedecocina.net/#logo",
              "inLanguage": "es-AR",
              "url": "https://fedecocina.net/imagenes/logo.png",
              "width": 800,
              "height": 800,
              "caption": "Fede Cocina"
            },
            "image": {
              "@id": "https://fedecocina.net/#logo"
            }
          },
          {
            "@type": "WebSite",
            "@id": "https://fedecocina.net/#website",
            "url": "https://fedecocina.net/",
            "name": "Fede Cocina",
            "description": "Las recetas más fáciles y rapidas para que prepares en tu casa.",
            "publisher": {
              "@id": "https://fedecocina.net/#organization"
            },
            "potentialAction": [
              {
                "@type": "SearchAction",
                "target": "https://fedecocina.net/busqueda-receta?search={search_term_string}",
                "query-input": "required name=search_term_string"
              }
            ],
            "inLanguage": "es-AR"
          },
          {
            "@type": "ImageObject",
            "@id": "https://fedecocina.net/receta/` + recipeData.pageUrl + `/#primaryimage",
            "inLanguage": "es-AR",
            "url": "https://fedecocina.net` + primaryImage + `",
            "caption": "Una foto de ` + recipeData.shortName + `"
          },
          {
            "@type": "WebPage",
            "@id": "https://fedecocina.net/receta/` + recipeData.pageUrl + `/#webpage",
            "url": "https://fedecocina.net/receta/` + recipeData.pageUrl + `/",
            "name": "` + recipeData.shortName + ` - Fede Cocina",
            "isPartOf": {
              "@id": "https://fedecocina.net/#website"
            },
            "primaryImageOfPage": {
              "@id": "https://fedecocina.net/receta/` + recipeData.pageUrl + `/#primaryimage"
            },
            "datePublished": "` + isoDate + `",
            "description": "` + recipeData.introText + `",
            "breadcrumb": {
              "@id": "https://fedecocina.net/receta/` + recipeData.pageUrl + `/#breadcrumb"
            },
            "inLanguage": "es-AR",
            "potentialAction": [
              {
                "@type": "ReadAction",
                "target": [
                  "https://fedecocina.net/receta/` + recipeData.pageUrl + `/"
                ]
              }
            ]
          },
          {
            "@type": "BreadcrumbList",
            "@id": "https://fedecocina.net/receta/` + recipeData.pageUrl + `/#breadcrumb",
            "itemListElement": [
              {
                "@type": "ListItem",
                "position": 1,
                "item": {
                  "@type": "WebPage",
                  "@id": "https://fedecocina.net/",
                  "url": "https://fedecocina.net/",
                  "name": "Home"
                }
              },
              {
                "@type": "ListItem",
                "position": 2,
                "item": {
                  "@type": "WebPage",
                  "@id": "https://fedecocina.net/todas-las-recetas",
                  "url": "https://fedecocina.net/todas-las-recetas",
                  "name": "Todas las Recetas"
                }
              },
              {
                "@type": "ListItem",
                "position": 3,
                "item": {
                  "@type": "WebPage",
                  "@id": "https://fedecocina.net/recetas/` + recipeData.categoryPageUrl + `/",
                  "url": "https://fedecocina.net/recetas/` + recipeData.categoryPageUrl + `/",
                  "name": "` + recipeData.category + `"
                }
              },
              {
                "@type": "ListItem",
                "position": 4,
                "item": {
                  "@type": "WebPage",
                  "@id": "https://fedecocina.net/receta/` + recipeData.pageUrl + `/",
                  "url": "https://fedecocina.net/receta/` + recipeData.pageUrl + `/",
                  "name": "` + recipeData.name + `"
                }
              }
            ]
          },
          {
            "@type": "Person",
            "@id": "https://fedecocina.net/#person",
            "name": "Federico Quevedo",
            "image": {
              "@type": "ImageObject",
              "@id": "https://fedecocina.net/#personlogo",
              "inLanguage": "es-AR",
              "url": "https://fedecocina.net/imagenes/Federico Quevedo.jpg",
              "caption": "Federico Quevedo",
              "width": 800,
              "height": 800
            }
          },
          {
            "@context": "http://schema.org/",
            "@type": "Recipe",
            "name": "` + recipeData.shortName + `",
            "image": [
              "https://fedecocina.net` + primaryImage + `",
              "https://fedecocina.net/imagenes/4x3/` + recipeData.thumbName + `.jpg",
              "https://fedecocina.net` + squaredImage + `"
            ],
            "author": {
              "@type": "Person",
              "name": "Federico Quevedo"
            },
            "datePublished": "` + isoDate + `",
            "description": "` + recipeData.introText + `",
            ` + videoStructure + `
            "recipeYield": [
              "` + recipeData.recipeYield + `",
              "` + recipeData.recipeYield + ` ` + recipeData.recipeYieldTypePlural + `"
            ],
            ` + prepTime + `
            ` + cookTime + `
            ` + totalTime + `
            "recipeIngredient": [` + recipeIngredient + `],
            "recipeInstructions": [` + recipeInstructions + `],
            ` + aggregateRating + `
            "recipeCategory": [
              "` + recipeData.SDRecipeCategory + `"
            ],
            "recipeCuisine": [
              "` + recipeData.SDRecipeCuisine + `"
            ],
            "keywords": "` + recipeData.tags + `",
            "nutrition": {
              "@type": "NutritionInformation",
              "calories": "` + (nutrition ? nutrition.calories : 0) + ` kcal",
              "carbohydrateContent": "` + (nutrition ? nutrition.carbohydrateContent : 0) + ` g",
              "proteinContent": "` + (nutrition ? nutrition.proteinContent : 0) + ` g",
              "fatContent": "` + (nutrition ? nutrition.fatContent : 0) + ` g",
              "saturatedFatContent": "` + (nutrition ? nutrition.saturatedFatContent : 0) + ` g",
              "transFatContent": "` + (nutrition ? nutrition.transFatContent : 0) + ` g",
              "cholesterolContent": "` + (nutrition ? nutrition.cholesterolContent : 0) + ` mg",
              "sodiumContent": "` + (nutrition ? nutrition.sodiumContent : 0) + ` mg",
              "fiberContent": "` + (nutrition ? nutrition.fiberContent : 0) + ` g",
              "sugarContent": "` + (nutrition ? nutrition.sugarContent : 0) + ` g",
              "unsaturatedFatContent": "` + (nutrition ? nutrition.unsaturatedFatContent : 0) + ` g",
              "servingSize": "1 ` + recipeData.recipeYieldType + `"
            },
            "@id": "https://fedecocina.net/receta/` + recipeData.pageUrl + `/#recipe",
            "mainEntityOfPage": "https://fedecocina.net/receta/` + recipeData.pageUrl + `/#webpage"
          }
        ]
      }`;
}